import React, { useEffect, useState, useRef } from "react";
import { retrieveLaunchParams, postEvent, on, init, backButton, popup, subscribe, unsubscribe } from '@telegram-apps/sdk';
import '@telegram-apps/telegram-ui/dist/styles.css';
import './messages.css';
import { AppRoot, Cell, List, Section, Avatar, Button, Banner, Switch, Title, Input, Divider, Info, Tabbar, IconButton, Spinner, FixedLayout, SegmentedControl, IconContainer, Card } from '@telegram-apps/telegram-ui';
import { FaListUl, FaCog, FaArrowDown } from 'react-icons/fa'; // Импорт всех иконок из Font Awesome
import { FaCircleInfo, FaGears, FaStar, FaRetweet } from 'react-icons/fa6';

import Lottie from "lottie-react";
import lottieOnline from "./lottieOnline.json";
import lottieLoader from "./lottieLoader.json";

const backendurl = 'https://'+process.env.REACT_APP_DOMAIN+'/api'  //without last slash

async function fetchBackend(initDataRaw, path, method='GET', body=null) {
  try {
    const params = method === 'POST'?{
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'x-init-data': initDataRaw, // Передаём initDataRaw в заголовках
      },
      body: JSON.stringify(body)
    }:{
      method: method,
      headers: {
        'x-init-data': initDataRaw, // Передаём initDataRaw в заголовках
      },
    };
    const response = await fetch(backendurl + path, params);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Ошибка при запросе:', error.message);
    throw error; // Пробрасываем ошибку для обработки на уровне вызова
  }
}

const tabs = [
  {id: 0, text: 'Диалоги', icon: <FaListUl size={28}/>},
  {id: 1, text: 'Настройки', icon: <FaCog size={28}/>}
];

const tarifs = [
  {
    id: 0,
    name: 'Бесплатный',
    littleDescription: `Для всех`,
    description: `Включает в себя:
  - общение выбором варианта
  - отправка рекламы бота собеседникам раз в несколько сообщений
  - 50 генераций в сутки`,
    generationLimit: 50,
    tgformat: {label: 'free', amount: 0},
  },
  {
    id: 1,
    name: 'Базовый',
    littleDescription: `Стандартный набор нейро-проказника`,
    description: `Включает в себя:
  - всё, что бесплатный
  - бот не отправляет свою рекламу собеседникам
  - можно включить автоматические ответы 24/7
  - 5.000 сообщений в сутки`,
    generationLimit: 5000,
    tgformat: {label: 'basic', amount: 500},
  }
];

const App = () => {
  const { initDataRaw, initData } = retrieveLaunchParams();
  window.TelegramGameProxy = { receiveEvent() {} };

  const [currentUser, setCurrentUser] = useState({});
  const [registeredByNumber, setRegisteredByNumber] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sentCode, setSentCode] = useState(false);
  const [smsCode, setSmsCode] = useState('');
  const [smsCodeApproved, setSmsCodeApproved] = useState(false);
  const [dfaNeeded, setDfaNeeded] = useState(false);
  const [dfaPass, setDfaPass] = useState('');
  const [showNewScreen, setShowNewScreen] = useState(false);
  const [chatlist, setChatlist] = useState([]);
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  const [aIWorks, setAIWorks] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(0);
  const [generatedAnswers, setGeneratedAnswers] = useState([{status: 'pending', answer: ''}, {status: 'pending', answer: ''}, {status: 'pending', answer: ''}]);
  const [answerIsSending, setAnswerIsSending] = useState(false);
  const [tarifsId, settarifsId] = useState(0);
  const [CU, setCU] = useState(false);

  let aliveLoop;
  let dialogUpdaterInterval;

  init();
  backButton.mount();

  async function generateFewAnswers(useridforwhogenerating){
      const promises = [0, 1, 2].map(async (i) => {
        const answ = await fetchBackend(initDataRaw, '/generateAnswer', 'POST', { chatid: useridforwhogenerating });
        console.log(answ);
        return { index: i, answ };
      });

      try {
        const results = await Promise.all(promises);
        // Проверяем, есть ли хотя бы один ответ с "endLimit"
        const limitReached = results.some(({ answ }) => answ?.error === 'endLimit');
        if (limitReached) {
          console.log('limit reached catched');
          alert('К сожалению, вы превысили лимит генераций на сегодня. Завтра он обнулится.');
          return; // Завершаем выполнение
        }
        setGeneratedAnswers((prev) => {
          const updatedAnswers = [...prev];
          results.forEach(({ index, answ }) => {
            updatedAnswers[index] = answ;
          });
          return updatedAnswers;
        });
      } catch (error) {
        console.error('Ошибка при обработке запросов:', error);
      }
  }

  async function generateOneAnswer(useridforwhogenerating, idwheretoplace){
      const answ = await fetchBackend(initDataRaw, '/generateAnswer', 'POST', { chatid: useridforwhogenerating });
      console.log(answ);

      try {
        if (answ.error !== undefined && answ.error === 'endLimit') {
          console.log('limit reached catched');
          alert('К сожалению, вы превысили лимит генераций на сегодня. Завтра он обнулится.');
          return; // Завершаем выполнение
        }

        setGeneratedAnswers((prev) => {
          const updatedAnswers = [...prev];
          updatedAnswers[idwheretoplace] = answ;
          return updatedAnswers;
        });
      } catch (error) {
        console.error('Ошибка при обработке запросов:', error);
      }
  }

  // dialog updater
  useEffect(() => {
    // Если CU не установлен, не запускаем интервал
    if (CU === false) {
      return;
    }

    // Создаём интервал
    const dialogUpdaterInterval = setInterval(() => {
      console.log('Interval running...');
      fetchBackend(initDataRaw, '/getChatData', 'POST', { chatid: CU.id }).then((r) => {
        console.log('dialogUpdaterInterval worked.');
        if(messages[messages.length-1].message !== r.messages[r.messages.length-1].message){
          // setGeneratedAnswers([{status: 'pending', answer: ''}, {status: 'pending', answer: ''}, {status: 'pending', answer: ''}]);
          // generateOneAnswer(CU.id, selectedAnswerIndex);
        }
        setMessages(r.messages);
      });
    }, 5000);

    // Очищаем интервал при изменении CU или размонтировании компонента
    return () => {
      console.log('Clearing interval...');
      clearInterval(dialogUpdaterInterval);
    };
  }, [CU, messages]); // Зависимость от CU

  // initial (first dashboard fetch)
  useEffect(() => {
    backButton.hide()

    backButton.onClick(() => {
      setShowNewScreen(false);
      backButton.hide()
      setGeneratedAnswers([{status: 'pending', answer: ''}, {status: 'pending', answer: ''}, {status: 'pending', answer: ''}]);
      setMessages([]);
      setCU(false);
      fetchBackend(initDataRaw, '/dashboard', 'GET').then(r => {
        if(r.chatlist != undefined){
          setChatlist(r.chatlist)
          delete r.chatlist
        }
        setCurrentUser(r);
        settarifsId(r.tariff)
      });
    });

    fetchBackend(initDataRaw, '/dashboard', 'GET').then(r => {
      if(r.error != undefined){
        switch (r.error) {
          case 'authbroken':
            alert('Аккаунт вылетел, к сожалению. Нужно перезайти')
            // eslint-disable-next-line no-restricted-globals
            window.location.reload();
            break;
          default:
            alert('Аккаунт вылетел, к сожалению. Нужно перезайти')
            // eslint-disable-next-line no-restricted-globals
            window.location.reload();
            return;
        }
      }
      if(r.registered === 1){
        setRegisteredByNumber(true)
        aliveLoop = setInterval(() => {
          fetchBackend(initDataRaw, '/alive', 'GET').then(r => {
            if(r.status == 'ok'){
              console.log('client alive');
              setAIWorks(true);
            }else if(r.status == 'authbroke'){
              alert('Аккаунт вылетел, к сожалению. Нужно перезайти')
              // eslint-disable-next-line no-restricted-globals
              window.location.reload();
            }else{
              setAIWorks(false);
              console.log(r.error);
            }
          }).catch(e => {
            setAIWorks(false);
            console.log(r.error);
          })
        }, 10000);
        if(r.chatlist != undefined){
          setChatlist(r.chatlist)
          delete r.chatlist
        }
        setCurrentUser(r);
        settarifsId(r.tariff)
      }else{
        setRegisteredByNumber(false)
      }
    })
  }, []);

  // event about subscription purchase
  useEffect(() => {
    const MiniAppsSubscribeListener = (event) => {
      console.log('Received event', event);
      if(event[0] === 'invoice_closed'){
        if(event[1].status == 'paid'){
          postEvent('web_app_open_popup', {
            title: 'Ура!',
            message: 'Готово! Подписка активна.',
            buttons: [
              { id: 'ok', type: 'ok', text: 'ok' },
            ]
          });
          // eslint-disable-next-line no-restricted-globals
          window.location.reload();
        }else if(event[1].status == 'cancelled'){
          postEvent('web_app_open_popup', {
            title: 'Что-ж!',
            message: 'Нет - так нет.',
            buttons: [
              { id: 'ok', type: 'ok', text: 'ok' },
            ]
          });
        }
      }
    };
    subscribe(MiniAppsSubscribeListener);
    // Удаляем слушатель при размонтировании компонента
    return () => {
      unsubscribe(MiniAppsSubscribeListener);
    };
  }, []);


  // buy subscription
  const buySubscription = (id) => {
    fetchBackend(initDataRaw, '/createInvoice', 'POST', {tariff: id}).then(r => {
      postEvent('web_app_open_invoice', {slug: r.link.split('$')[1]})
    })
  };

  // open specific dialog
  const openNewScreen = (id) => {
    setShowNewScreen(true);
    setCU(chatlist[id]);
    backButton.show()
    fetchBackend(initDataRaw, '/getChatData', 'POST', {chatid: chatlist[id].id}).then(r => {
      setMessages(r.messages)
    });
    setTimeout(() => {
      scrollToBottom();
    }, 1500);
    generateFewAnswers(chatlist[id].id);
  };

  // send sms, first step of authorization
  const sendCode = () => {
    setSentCode(true);
    fetchBackend(initDataRaw, '/auth', 'POST', {phoneNumber: phoneNumber}).then(r => {
      if(r.status == 'okWaitForCode'){
      }else{
        alert('Не удалось авторизоваться, проблемы на сервере...')
      }
    })
  }

  // user entered sms code
  const hereIsMyCode = () => {
    fetchBackend(initDataRaw, '/authcode', 'POST', {phoneNumber: phoneNumber, code: smsCode}).then(r => {
      if(r.status == 'gotit'){
        setSmsCodeApproved(true);
        setRegisteredByNumber(true);
      }else if(r.status == 'need2fa'){
        setSmsCodeApproved(true);
        setDfaNeeded(true);
      }else if(r.status == 'authexpired'){
       alert('Сессия входа истекла, попробуйте ещё раз')
      }
    })
  }

  // user entered dfa password
  const hereIsMyDfa = () => {
    fetchBackend(initDataRaw, '/authpass', 'POST', {phoneNumber: phoneNumber, pass2fa: dfaPass}).then(r => {
      if(r.status == 'gotit'){
        setRegisteredByNumber(true);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }else if(r.status == 'authexpired'){
       alert('Сессия входа истекла, попробуйте ещё раз')
     }else {
       alert('Почему-то не получилось')
     }
    })
  }

  // scroll to button
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight, // Прокрутить до конца страницы
      behavior: 'smooth', // Гладкая прокрутка
    });
  }

  // send selected answer
  const sendSelected = () => {
    setAnswerIsSending(true)
    fetchBackend(initDataRaw, '/sendAnswer', 'POST', { chatid: CU.id, answer: generatedAnswers[selectedAnswerIndex].answer}).then(r =>{
      if(r.status == 'ok'){
        setAnswerIsSending(false);
        // setGeneratedAnswers([{status: 'pending', answer: ''}, {status: 'pending', answer: ''}, {status: 'pending', answer: ''}]);
        // generateOneAnswer(CU.id, selectedAnswerIndex);
      }
    })
  };

  const AIInfo = () => {
    alert("Зайдите в любой диалог, и искусственный интеллект сможет предлагать вам варианты ответов.");
  }

  // render
  let content;
  if(!registeredByNumber){
    content = (
      <div>
        <Cell multiline='true' description='Чтобы искусственный интеллект мог работать - у нас нужно обязательно дополнительно авторизоваться.'>
          <Title level="2" weight="3">
          Привет!
          </Title>
        </Cell>
        <Input header="Номер телефона" placeholder="+799999999" onChange={e => setPhoneNumber(e.target.value)} />
        <Cell after={sentCode ? "Код отправлен" : <Button mode="filled" size="m" onClick={sendCode}>Отправить код</Button>}>
        </Cell>
        <br/>
        <Divider style={{borderWidth: '5px'}} />
      </div>
    )
    if(sentCode){
      if(!smsCodeApproved){
        // Добавляем дополнительный JSX
        content = (
          <>
            {content}
            <div>
              <Input header="Код из СМС или от Telegram" placeholder="342857" value={smsCode} onChange={e => setSmsCode(e.target.value)}/>
              <Cell after={<Button mode="filled" size="m" onClick={hereIsMyCode}>Подтвердить</Button>}>
              </Cell>
            </div>
          </>
        );
      }else{
        content = (
          <>
            {content}
            <div>
              <Input header="Код из СМС или от Telegram" placeholder="342857" value={smsCode} disabled/>
              <Cell after={"Готово!"}>
              </Cell>
            </div>
          </>
        );
      }
      if(dfaNeeded){
        // Добавляем дополнительный JSX
        content = (
          <>
            {content}
            <div>
              <Input header="Облачный пароль от Telegram" placeholder="password" value={dfaPass} onChange={e => setDfaPass(e.target.value)}/>
              <Cell after={<Button mode="filled" size="m" onClick={hereIsMyDfa}>Подтвердить</Button>}>
              </Cell>
            </div>
          </>
        );
      }
    }
  }else{
    if(!showNewScreen){
      if(currentTab == 0){
        content = (
          <List>
            <Cell style={{height: '4vh'}} onClick={AIInfo}>
            {aIWorks ? (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '85vw'}}><Lottie animationData={lottieOnline} style={{height: 40, width: 40}} loop={true} />AI</div>
            ):(
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '85vw'}}><Lottie animationData={lottieLoader} style={{height: 40, width: 40}} loop={true} />AI</div>
            )}
            </Cell>
            <Section footer="AI, 2024">
            {chatlist.map((cellText, index) => {
              let whatToShow;

               if(cellText[3] == 'acronym'){
                 whatToShow = { acronym: cellText.realName.substr(0, 2) };
               }else{
                 whatToShow = { src: backendurl+'/photo/'+cellText.username};
               }

               return (
                 <Cell
                   key={index}
                   before={<Avatar size={48} {...whatToShow} />}
                   subtitle={cellText.lastMessage !== null ? cellText.lastMessage : '...'}
                   onClick={() => {aIWorks?openNewScreen(index):alert('Минутку... ИИ загружается')}}
                   disabled={!aIWorks}
                 >
                   {cellText.realName}
                 </Cell>
               );
             })}
            </Section>
          </List>
        )
      }else if(currentTab == 1){
        function formatSubscriptionDate(dateString) {
            // Преобразуем строку в объект Date
            const date = new Date(dateString);
            // Проверяем, равна ли дата "1970-01-01"
            if (date.getTime() === 1000) {
                return "У вас бесплатный тариф";
            }

            // Форматируем дату в человекочитаемом формате
            const formattedDate = date.toLocaleDateString("ru-RU", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            });

            return `Ваш тариф активен до ${formattedDate}`;
        }
        content = (
          <List>
            <Section header="Основное">
              <Cell onClick={() => {alert('Привет!\nЭтот бот позволяет общаться с людьми с помощью искусственного интеллекта.\nИкусственный интеллект анализирует твой диалог, и предполагает, что бы ты мог ответить дальше. Зайди в любой диалог, дождись генерации, выбери любой вариант который нравится и отправь.')}} before={<IconContainer><FaCircleInfo /></IconContainer>}>
                О приложении
              </Cell>
              <Cell disabled before={<IconContainer><FaGears /></IconContainer>}>
                Настройки аккаунта
              </Cell>
              <Cell disabled before={<IconContainer><FaCircleInfo /></IconContainer>}>
                Скоро появится...
              </Cell>
            </Section>
            <Section header="Тарифы">
                {tarifs.map((tarif, id) => (
                  <Banner key={id} callout={<>{tarif.tgformat.amount} <FaStar style={{color: 'yellow'}} size={15} />/месяц</>} description={tarif.littleDescription} header={tarif.name} type="section">
                    <React.Fragment key=".0">
                      {currentUser.tariff < id?<Button onClick={() => buySubscription(id)} mode="filled" size="s">купить</Button>:<Button disabled mode="white" size="s">Текущий</Button>}
                      <Button onClick={() => alert(tarif.description)} mode="outline" size="s">подробнее</Button>
                    </React.Fragment>
                  </Banner>
                ))}
            </Section>
            <Cell style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '85vw'}}>
              <span>
                {formatSubscriptionDate(currentUser.payed_and_if_yes_then_until)}<br/>
                Осталось генераций на сегодня: {currentUser.today_limit}
              </span>
            </Cell>
            <br/>
            <br/>
            <br/>
            <br/>
          </List>
        )
      }

      content = (
        <>
        {content}
        <Tabbar>
          {tabs.map(({
          id,
          text,
          icon
        }) => <Tabbar.Item key={id} text={text} selected={id === currentTab} onClick={() => setCurrentTab(id)}>
              {icon}
            </Tabbar.Item>)}
        </Tabbar>
        </>
      )
    }else{
      let msgprepared;
      if(messages.length !== 0){
        for (var i = 0; i < messages.length; i++) {
          msgprepared = (
            <>
            {msgprepared}
            <div className={messages[i].from===false?'message message-from':'message message-to'}>
              <p className="message-content">{messages[i].message}</p>
              <div className={messages[i].from===false?'message-timestamp-left':'message-timestamp-right'}>{messages[i].from===false?CU.realName:'Вы'}</div>
            </div>
            </>
          );
        }
      }
      content = (
        <div>
            <Cell multiline='true' hovered='true' description='Отвечайте с помощью искусственного интеллекта'>
              <Title level="2" weight="3">
                {CU.realName}
              </Title>
            </Cell>
          <div className='messages'>
            <br/>
            {messages.length === 0?<Lottie animationData={lottieLoader} style={{height: 60, width: 60}} loop={true} />:msgprepared}
          </div>
          <Cell>
            <FixedLayout vertical='bottom' style={{backgroundColor: 'var(--tgui--tertiary_bg_color)', height: '40%'}}>
              <Cell after={<FaArrowDown/>} onClick={scrollToBottom} style={{marginTop: '-10%'}}></Cell>
              <div>
              <Cell before={<FaRetweet size='23'/>} onClick={() => {
                setGeneratedAnswers((prev) => {
                  const updatedAnswers = [...prev];
                  updatedAnswers[selectedAnswerIndex] = {status: 'pending', answer: ''};
                  return updatedAnswers;
                });
                generateOneAnswer(CU.id, selectedAnswerIndex);
              }} style={{paddingTop: '5px'}}></Cell>
                <div style={{whiteSpace: 'normal', wordBreak: 'break-word', margin: '12px', maxHeight: '130px', overflowY: 'auto'}}>
                  {generatedAnswers[selectedAnswerIndex] === undefined || generatedAnswers[selectedAnswerIndex].status === 'pending'?<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90vw'}}><Spinner size="l" /></div>:generatedAnswers[selectedAnswerIndex].answer}
                </div>
                <FixedLayout vertical='bottom'>
                  <SegmentedControl>
                    <SegmentedControl.Item
                      onClick={() => {setSelectedAnswerIndex(0);if(generatedAnswers[selectedAnswerIndex].status == 'pending') generateOneAnswer(CU.id, selectedAnswerIndex);}}
                      selected={selectedAnswerIndex === 0}
                    >
                      Вариант 1
                    </SegmentedControl.Item>
                    <SegmentedControl.Item
                      onClick={() => {setSelectedAnswerIndex(1);if(generatedAnswers[selectedAnswerIndex].status == 'pending') generateOneAnswer(CU.id, selectedAnswerIndex);}}
                      selected={selectedAnswerIndex === 1}
                    >
                      Вариант 2
                    </SegmentedControl.Item>
                    <SegmentedControl.Item
                      onClick={() => {setSelectedAnswerIndex(2);if(generatedAnswers[selectedAnswerIndex].status == 'pending') generateOneAnswer(CU.id, selectedAnswerIndex);}}
                      selected={selectedAnswerIndex === 2}
                    >
                      Вариант 3
                    </SegmentedControl.Item>
                  </SegmentedControl>
                  <br/>
                  <Button onClick={sendSelected} mode={answerIsSending?'outline':'filled'} disabled={generatedAnswers[selectedAnswerIndex] === undefined || generatedAnswers[selectedAnswerIndex].status === 'pending' || answerIsSending} style={{width: '100%'}} size="l">
                    {answerIsSending?<Spinner size="l"/>:'Отправить'}
                  </Button>
                </FixedLayout>
              </div>
            </FixedLayout>
          </Cell>
        </div>
      )
    }
  }

  return (<AppRoot>{content}</AppRoot>);
};

export default App;
